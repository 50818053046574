import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-header-small",
  templateUrl: "./header-small.component.html",
})
export class HeaderSmallComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
